import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Creating the perfect rounded edge with D3 curves",
  "description": "A coaching client showed me this design and asked: \"Ok, how do I build this?\"",
  "date": "2018-07-31T08:00:00.000Z",
  "published": "2018-07-31T08:00:00.000Z",
  "image": "./img/blog-wp-content-uploads-2018-07-tree-with-rounded-edges.png"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`A coaching client showed me this design and asked: `}<em parentName="p">{`Ok, how do I build this?`}</em></p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {
          "textAlign": "center",
          "fontStyle": "italic"
        }
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "890px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/4ef22461ba2fac7e644c4c87157586c3/daed9/blog-wp-content-uploads-2018-07-tree-with-rounded-edges.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "59.64125560538116%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/svg+xml,%3csvg%20xmlns=\\'http://www.w3.org/2000/svg\\'%20width=\\'400\\'%20height=\\'238\\'%20viewBox=\\'0%200%20400%20238\\'%20preserveAspectRatio=\\'none\\'%3e%3cpath%20d=\\'M6%206c-1%202%200%205%201%205%203%201%206%200%206-2h1c0%202%204%203%206%202h15c1%202%203%200%203-2s0-2-1%200l-1%202V9c0-2%200-2-1-1h-2l-2-1c-1%201-3%200-3-1-2-2-3-1-3%202l-1%202c-2%200-2-2-1-2%201-1-1-1-3-1h-6c-2-2-7-2-8-1m135%200l-1%203c0%202%203%204%204%202v-1l-2-2c0-3%202-3%203%200l1%201%201%202c1%202%203%201%203-1%201-1%201-1%201%201s1%203%203%201c1-2%204-2%206-1l2-1c2-1%202-1%202%201s0%202%202%201l1-4h-2l-4-1c-2%201-3%200-3-1l-1-1-1%201c0%201-2%202-5%202l-5-2h-5M19%2022c-5%204-3%2014%203%2014%208%201%2013-7%208-13-3-4-8-4-11-1m67%200c-4%205-2%2012%204%2014%203%201%209-2%2010-5%203-8-7-15-14-9m59%201c-3%202-4%207-2%2010%202%201%202%201%202-2%200-5%202-7%205-7%206%200%208%206%204%209l-2%204c1%203%207-4%207-8%200-7-9-11-14-6m30%2067c-5%202-6%209-2%2013%203%203%206%204%209%202%2010-5%203-19-7-15m58%202c-5%205-2%2014%206%2014%202%200%204%200%206-2%207-8-4-19-12-12m2%2053c-5%203-6%209-2%2014%205%205%2014%201%2014-6%200-6-7-10-12-8m-59%201c-7%204-6%2014%201%2015%209%202%2014-9%207-14-3-2-6-2-8-1m-2%2056c-4%204-3%2011%202%2013s12-2%2012-7l-3-6-5-3-6%203m120-1c-4%204-3%2011%202%2014%206%203%2013-4%2012-10-2-6-9-8-14-4m-60%201c-5%206-2%2014%206%2014%207%200%2011-9%206-14-3-3-9-3-12%200\\'%20fill=\\'%23d3d3d3\\'%20fill-rule=\\'evenodd\\'/%3e%3c/svg%3e')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/4ef22461ba2fac7e644c4c87157586c3/ca0a1/blog-wp-content-uploads-2018-07-tree-with-rounded-edges.webp 223w", "/static/4ef22461ba2fac7e644c4c87157586c3/75680/blog-wp-content-uploads-2018-07-tree-with-rounded-edges.webp 445w", "/static/4ef22461ba2fac7e644c4c87157586c3/8d1ba/blog-wp-content-uploads-2018-07-tree-with-rounded-edges.webp 890w", "/static/4ef22461ba2fac7e644c4c87157586c3/3838e/blog-wp-content-uploads-2018-07-tree-with-rounded-edges.webp 1335w", "/static/4ef22461ba2fac7e644c4c87157586c3/52cea/blog-wp-content-uploads-2018-07-tree-with-rounded-edges.webp 1426w"],
                "sizes": "(max-width: 890px) 100vw, 890px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/4ef22461ba2fac7e644c4c87157586c3/e92b6/blog-wp-content-uploads-2018-07-tree-with-rounded-edges.png 223w", "/static/4ef22461ba2fac7e644c4c87157586c3/e66bf/blog-wp-content-uploads-2018-07-tree-with-rounded-edges.png 445w", "/static/4ef22461ba2fac7e644c4c87157586c3/4ef49/blog-wp-content-uploads-2018-07-tree-with-rounded-edges.png 890w", "/static/4ef22461ba2fac7e644c4c87157586c3/4e814/blog-wp-content-uploads-2018-07-tree-with-rounded-edges.png 1335w", "/static/4ef22461ba2fac7e644c4c87157586c3/daed9/blog-wp-content-uploads-2018-07-tree-with-rounded-edges.png 1426w"],
                "sizes": "(max-width: 890px) 100vw, 890px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/4ef22461ba2fac7e644c4c87157586c3/4ef49/blog-wp-content-uploads-2018-07-tree-with-rounded-edges.png",
                "alt": "Tree structure with rounded edges",
                "title": "Tree structure with rounded edges",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Tree structure with rounded edges`}</figcaption>{`
  `}</figure>{` Tree structure with rounded edges`}</p>
    <p>{`Well hmm… it`}{`'`}{`s a tree of some sort. Each icon is a node, and each line is an edge. Nodes can have multiple children. I think 🤔`}</p>
    <p>{`You can build this with D3. Calculate each node`}{`'`}{`s position, then iterate and render. An image `}{`\\`}{`imported as an SVG component will do. Webpack can handle that for you.`}</p>
    <p>{`So you have node positions and you know which nodes are connected. Now what?`}</p>
    <p>{`You can turn that into pairs of coordinates. Point A to point B. A line between them.`}</p>
    <p>{`D3 is perfect for that! Drawing lines from A to B is super easy 👇`}</p>
    <pre><code parentName="pre" {...{}}>{`// create a line path generator
const line = d3.line();
// pair of coordinates
const data = [[0, 0], [100, 100]];

// draw, using JSX notation


`}</code></pre>
    <p>{`That draws a line from point `}<inlineCode parentName="p">{`(0, 0)`}</inlineCode>{` to point `}<inlineCode parentName="p">{`(100, 100)`}</inlineCode>{`.`}</p>
    <p>{`But we don`}{`'`}{`t want straight lines. Straight lines don`}{`'`}{`t look good.`}</p>
    <p>{`Lucky for us, D3 has ample support for curves. Many different curve formulas to choose from.`}</p>
    <p>{`We can add some curve to our line with the `}<inlineCode parentName="p">{`.curve`}</inlineCode>{` method.`}</p>
    <pre><code parentName="pre" {...{}}>{`// create a line path generator
const line2 = d3.line().curve(d3.curveCardinal);
`}</code></pre>
    <p>{`If you try that, you`}{`'`}{`ll see that nothing happens. Curves need multiple points in your line data to work well.`}</p>
    <p>{`Like this 👇`}</p>
    <p>{`That`}{`'`}{`s a nice curve and all, but not quite what we`}{`'`}{`re looking for. And if you look at the `}<a parentName="p" {...{
        "href": "https://github.com/d3/d3-shape/blob/master/README.md#curves"
      }}>{`curve examples in D3 docs`}</a>{`, you`}{`'`}{`ll see that nothing quite fits.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "888px",
          "textAlign": "center",
          "fontStyle": "italic"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/70bdd083a3377ee01af1f73bbf99ffde/b04e4/d3-d3-shape-master-img-cardinal.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "26.905829596412556%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/svg+xml,%3csvg%20xmlns=\\'http://www.w3.org/2000/svg\\'%20width=\\'400\\'%20height=\\'108\\'%20viewBox=\\'0%200%20400%20108\\'%20preserveAspectRatio=\\'none\\'%3e%3cpath%20d=\\'M113%2015l-4%201-3%201-8%205-11%207c-2%203-5%205-6%205l-2%202-10%2016-22%2034%2016-22%2019-27a126%20126%200%200127-18c2-1%2013-2%2016%200l4%206%203%204-2-5-2-5c0-2-6-6-9-6l-6%202m19%2016c0%203%2011%2029%2014%2034%206%208%206%208%2014%2011%2010%203%2028%202%2039-1l7-1c2%201%2019-17%2019-20l-8%208-11%209c-3%202-49%202-52%200l-12-21-10-19m110%204l-6%205c-4%203-11%2012-11%2014l8-8c5-4%209-8%2011-8a228%20228%200%200145%2017c1-1%200-3-2-3l-10-5c-12-7-21-11-26-12a212%20212%200%2001-9%200m97%2027c-5%201-13%205-11%206l6-2c4-1%207-2%208-1%203%201%2026%2024%2026%2026h2c1-1-26-30-28-30l-3%201\\'%20fill=\\'%23d3d3d3\\'%20fill-rule=\\'evenodd\\'/%3e%3c/svg%3e')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/70bdd083a3377ee01af1f73bbf99ffde/ca0a1/d3-d3-shape-master-img-cardinal.webp 223w", "/static/70bdd083a3377ee01af1f73bbf99ffde/75680/d3-d3-shape-master-img-cardinal.webp 445w", "/static/70bdd083a3377ee01af1f73bbf99ffde/60055/d3-d3-shape-master-img-cardinal.webp 888w"],
              "sizes": "(max-width: 888px) 100vw, 888px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/70bdd083a3377ee01af1f73bbf99ffde/e92b6/d3-d3-shape-master-img-cardinal.png 223w", "/static/70bdd083a3377ee01af1f73bbf99ffde/e66bf/d3-d3-shape-master-img-cardinal.png 445w", "/static/70bdd083a3377ee01af1f73bbf99ffde/b04e4/d3-d3-shape-master-img-cardinal.png 888w"],
              "sizes": "(max-width: 888px) 100vw, 888px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/70bdd083a3377ee01af1f73bbf99ffde/b04e4/d3-d3-shape-master-img-cardinal.png",
              "alt": "d3 d3 shape master img cardinal",
              "title": "d3 d3 shape master img cardinal",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "888px",
          "textAlign": "center",
          "fontStyle": "italic"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/73f7473923b7fb563796a84f3419db21/b04e4/d3-d3-shape-master-img-catmullRom.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "26.905829596412556%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/svg+xml,%3csvg%20xmlns=\\'http://www.w3.org/2000/svg\\'%20width=\\'400\\'%20height=\\'108\\'%20viewBox=\\'0%200%20400%20108\\'%20preserveAspectRatio=\\'none\\'%3e%3cpath%20d=\\'M113%2015c-2%201-4%202-5%201l-1%201-5%203c-2%200-3%201-3%202l4-1%204-1%204-1c4-3%2010-2%2014%200%203%202%204%204%2010%2021l10%2024c3%204%203%203%201-2l-3-10-2-6c-1-1-5-14-4-16%200-2%200-2-1-1s-3-4-2-5l-1-1h-1l-3-4c-7-6-11-7-16-4M79%2036l-5%207c-6%207-14%2019-22%2034l-8%2012v2a344%20344%200%200139-56c0-2-3-1-4%201m206%2017l4%203%2011%207a95%2095%200%200014%2010c1%201%208-1%2014-5%207-4%2012-5%2013-4l2%201c2-1%2010%206%2017%2014s10%2010%204%203c-5-7-11-13-17-17-6-5-7-5-20%202l-10%204h-3c-1%200-14-8-24-17l-4-2-3-1%202%202m-71%2014c-3%202-6%204-7%203l-1%201c0%201-8%204-14%205-13%202-37-1-37-4%200%200-1-2-3-2-3-2-4-1-1%202%205%206%2034%208%2049%203%203-1%206-2%207-1l12-11c0-1-3%201-5%204\\'%20fill=\\'%23d3d3d3\\'%20fill-rule=\\'evenodd\\'/%3e%3c/svg%3e')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/73f7473923b7fb563796a84f3419db21/ca0a1/d3-d3-shape-master-img-catmullRom.webp 223w", "/static/73f7473923b7fb563796a84f3419db21/75680/d3-d3-shape-master-img-catmullRom.webp 445w", "/static/73f7473923b7fb563796a84f3419db21/60055/d3-d3-shape-master-img-catmullRom.webp 888w"],
              "sizes": "(max-width: 888px) 100vw, 888px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/73f7473923b7fb563796a84f3419db21/e92b6/d3-d3-shape-master-img-catmullRom.png 223w", "/static/73f7473923b7fb563796a84f3419db21/e66bf/d3-d3-shape-master-img-catmullRom.png 445w", "/static/73f7473923b7fb563796a84f3419db21/b04e4/d3-d3-shape-master-img-catmullRom.png 888w"],
              "sizes": "(max-width: 888px) 100vw, 888px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/73f7473923b7fb563796a84f3419db21/b04e4/d3-d3-shape-master-img-catmullRom.png",
              "alt": "d3 d3 shape master img catmullRom",
              "title": "d3 d3 shape master img catmullRom",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`After some experimentation, I found a solution.`}</p>
    <lite-youtube {...{
      "videoid": "VyCP-3gL72k",
      "videostartat": "0"
    }}></lite-youtube>
    <p>{`👉 a React component that takes 2 points, injects 2 juuuust perfectly spaced points, and draws a D3 curve between them.`}</p>
    <p>{`The poorly named `}<inlineCode parentName="p">{`<RoundedCorner>`}</inlineCode>{` component is just 15 lines of Prettier`}{`'`}{`d code. All values discovered experimentally.`}</p>
    <pre><code parentName="pre" {...{}}>{`const RoundedCorner = ({ start, end, radius = 5 }) => {
  const line = d3
    .line()
    .x(d => d[0])
    .y(d => d[1])
    .curve(d3.curveBundle.beta(1));

  const points = [
    start,
    [start[0], end[1] - radius],
    [start[0] + radius, end[1]],
    end
  ];

  return ;
};
`}</code></pre>
    <p>{`We take `}<inlineCode parentName="p">{`start`}</inlineCode>{` and `}<inlineCode parentName="p">{`end`}</inlineCode>{` coordinates, and the desired `}<inlineCode parentName="p">{`radius`}</inlineCode>{`. Similar to CSS rounded borders.`}</p>
    <p>{`Experimentally, I discovered that for best results, you have to place 2 points between the two endpoints. One on each side of the rounded corner you want.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "890px",
          "textAlign": "center",
          "fontStyle": "italic"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/42bd88159edd9b95ecbdefbfaa62aadf/6389a/blog-wp-content-uploads-2018-07-sketch.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "79.82062780269058%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/svg+xml,%3csvg%20xmlns=\\'http://www.w3.org/2000/svg\\'%20width=\\'400\\'%20height=\\'319\\'%20viewBox=\\'0%200%20400%20319\\'%20preserveAspectRatio=\\'none\\'%3e%3cpath%20d=\\'M137%2045c-3%201-3%203%200%206l2%2050a33614%2033614%200%20000%2067c-2-1-3%203-1%205l4%207c7%2018%2025%2036%2039%2040l4%202h9c6-1%2053-2%2099-1h12v3c0%203%203%204%208%201%209-5%205-11-5-7l-27%201h-82l1-3c0-4-1-4-2-1-1%202-1-2-1-11%200-15%201-24%202-23l1-3c0-4-2-5-4-2-2%201-2%202-1%202a140%20140%200%20010%2039c1%202%200%202-4%202s-4%200-1-1l3-1h-8c-5%205-26-11-35-25-5-7-9-17-7-18v-2l-1-4c-1-3-1-3%202-3h3l18-1%2018%201v1h4v-1c0-4-2-5-4-4l-33%202c-5-1-6-1-5-3%200-2-2-3-2-1-2%204-2-5-3-53V53l3-3c6-5%201-9-6-5\\'%20fill=\\'%23d3d3d3\\'%20fill-rule=\\'evenodd\\'/%3e%3c/svg%3e')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/42bd88159edd9b95ecbdefbfaa62aadf/ca0a1/blog-wp-content-uploads-2018-07-sketch.webp 223w", "/static/42bd88159edd9b95ecbdefbfaa62aadf/75680/blog-wp-content-uploads-2018-07-sketch.webp 445w", "/static/42bd88159edd9b95ecbdefbfaa62aadf/8d1ba/blog-wp-content-uploads-2018-07-sketch.webp 890w", "/static/42bd88159edd9b95ecbdefbfaa62aadf/30504/blog-wp-content-uploads-2018-07-sketch.webp 1325w"],
              "sizes": "(max-width: 890px) 100vw, 890px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/42bd88159edd9b95ecbdefbfaa62aadf/853b2/blog-wp-content-uploads-2018-07-sketch.jpg 223w", "/static/42bd88159edd9b95ecbdefbfaa62aadf/0a4fe/blog-wp-content-uploads-2018-07-sketch.jpg 445w", "/static/42bd88159edd9b95ecbdefbfaa62aadf/7f80b/blog-wp-content-uploads-2018-07-sketch.jpg 890w", "/static/42bd88159edd9b95ecbdefbfaa62aadf/6389a/blog-wp-content-uploads-2018-07-sketch.jpg 1325w"],
              "sizes": "(max-width: 890px) 100vw, 890px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/42bd88159edd9b95ecbdefbfaa62aadf/7f80b/blog-wp-content-uploads-2018-07-sketch.jpg",
              "alt": "blog wp content uploads 2018 07 sketch",
              "title": "blog wp content uploads 2018 07 sketch",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Then you have to use the `}<inlineCode parentName="p">{`curveBundle`}</inlineCode>{` generator with the `}<inlineCode parentName="p">{`beta`}</inlineCode>{` factor set to `}<inlineCode parentName="p">{`1`}</inlineCode>{`. I honestly don`}{`'`}{`t know what that means but it works.`}</p>
    <p>{`You can see I tried a few different configurations in the example CodeSandbox. That`}{`'`}{`s because some curves produced weird edges when turned around like that.`}</p>
    <p>{`But not good ol`}{`'`}{` curveBundle.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      